import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTableViewFilters } from '../../../contexts/table-view/TableViewFilterContext';
import { StatusFilterValue } from '../../../models/TableViewFilters';
import { FilterProps } from './FilterProps';
import { ClientFormStatus, formStatusKeys } from '../../../models/ClientFormStatus';
import { DistributionStatusFilter, DistributionStatusFilterKeys } from '../../../models/Distribution';
import { PeriodicReviewStatus } from '../../../models/PeriodicReview';
import { useFeatureFlags } from '../../../contexts/FeatureFlagContext';
import { Option } from '../../Option';
import Checkbox from '../../shared/form-control/Checkbox';
import { mouseAndKeyboardCallbackProps } from '../../../utils/ComponentUtils';

const ARCHIVED_VALUE = 99;
const FORM_STATUS_PREFIX = 'form-status-';
const PERIODIC_STATUS_PREFIX = 'periodic-status-';
const DISTRIBUTION_STATUS_PREFIX = 'distribution-status-';

const StatusFilter: FC<FilterProps> = ({ columnConfig, selectedTemplateId }) => {
  const { t } = useTranslation(['table-view', 'common', 'distribution']);
  const { featureFlags } = useFeatureFlags();
  const { filters, setFilters } = useTableViewFilters();

  const filterValue = useMemo(() => {
    return filters?.[selectedTemplateId]?.[columnConfig.value]?.filter as StatusFilterValue | undefined;
  }, [columnConfig.value, filters, selectedTemplateId]);

  // Note this need to be documentStatusKeys when document space is using table view
  const defaultStatusFilters = useMemo<Option<string, boolean>[]>(() => {
    const clientFormStatusFilters = [
      { id: 'activity_status_heading', text: t('common:form-status.heading'), value: false, disabled: true, hasDivider: true },
      { id: `${FORM_STATUS_PREFIX}${ClientFormStatus.NotStarted}`, text: t(formStatusKeys[ClientFormStatus.NotStarted]), value: false },
      { id: `${FORM_STATUS_PREFIX}${ClientFormStatus.InProgress}`, text: t(formStatusKeys[ClientFormStatus.InProgress]), value: false },
      {
        id: `${FORM_STATUS_PREFIX}${ClientFormStatus.SubmittedForValidation}`,
        text: t(formStatusKeys[ClientFormStatus.SubmittedForValidation]),
        value: false,
      },
      {
        id: `${FORM_STATUS_PREFIX}${ClientFormStatus.SubmittedForApproval}`,
        text: t(formStatusKeys[ClientFormStatus.SubmittedForApproval]),
        value: false,
      },
      { id: `${FORM_STATUS_PREFIX}${ClientFormStatus.Completed}`, text: t(formStatusKeys[ClientFormStatus.Completed]), value: false },
      // NOTE when using document space this need to be archived-document: Obsolute
      { id: `${FORM_STATUS_PREFIX}${ARCHIVED_VALUE}`, text: t('common:list.filter.archived'), value: false },
    ];

    const periodicReviewFilters = [
      { id: 'periodic_status_heading', text: t('common:periodic-review-status.heading'), value: false, disabled: true, hasDivider: true },
      { id: `${PERIODIC_STATUS_PREFIX}${PeriodicReviewStatus.UpcomingReview}`, text: t('common:periodic-review-status.upcoming'), value: false },
      { id: `${PERIODIC_STATUS_PREFIX}${PeriodicReviewStatus.UnderReview}`, text: t('common:periodic-review-status.under-review'), value: false },
      { id: `${PERIODIC_STATUS_PREFIX}${PeriodicReviewStatus.ReviewCompleted}`, text: t('common:periodic-review-status.completed'), value: false },
      {
        id: `${PERIODIC_STATUS_PREFIX}${PeriodicReviewStatus.ReviewOverdue}`,
        text: t('common:periodic-review-status.overdue'),
        value: false,
        hasDivider: true,
      },
    ];

    const distributionFilters = featureFlags.distributionList
      ? [
          { id: 'distribution_status_heading', text: t('distribution:filters.heading'), value: false, disabled: true, hasDivider: true },
          {
            id: `${DISTRIBUTION_STATUS_PREFIX}${DistributionStatusFilter.Active}`,
            text: t(DistributionStatusFilterKeys[DistributionStatusFilter.Active]),
            value: false,
          },
          {
            id: `${DISTRIBUTION_STATUS_PREFIX}${DistributionStatusFilter.InActive}`,
            text: t(DistributionStatusFilterKeys[DistributionStatusFilter.InActive]),
            value: false,
          },
          {
            id: `${DISTRIBUTION_STATUS_PREFIX}${DistributionStatusFilter.ActionNeededByCurrentUser}`,
            text: t(DistributionStatusFilterKeys[DistributionStatusFilter.ActionNeededByCurrentUser]),
            value: false,
          },
          {
            id: `${DISTRIBUTION_STATUS_PREFIX}${DistributionStatusFilter.Completed}`,
            text: t(DistributionStatusFilterKeys[DistributionStatusFilter.Completed]),
            value: false,
          },
        ]
      : [];

    // NOTE add distributionFilters to the return array when document space is using table view
    return [...clientFormStatusFilters, ...periodicReviewFilters];
  }, [featureFlags.distributionList, t]);

  const updateFilter = (changedOption: Option<string, boolean>, isSelected: boolean) => {
    const updateStatusFilters = <T extends number>(currentFilters: T[], prefix: string): T[] => {
      if (changedOption.disabled || !changedOption.id.startsWith(prefix)) return currentFilters;
      const value = parseInt(changedOption.id.replace(prefix, ''));
      const typedValue = value as T;
      if (isSelected) {
        return [...new Set([...currentFilters, typedValue])]; // using a set to remove duplicates
      } else {
        return currentFilters.filter((filter) => filter !== typedValue);
      }
    };

    const clientFormStatusFilters = updateStatusFilters<ClientFormStatus>(filterValue?.clientFormStatusFilters ?? [], FORM_STATUS_PREFIX);
    const periodicReviewStatusFilters = updateStatusFilters<PeriodicReviewStatus>(
      filterValue?.periodicReviewStatusFilters ?? [],
      PERIODIC_STATUS_PREFIX,
    );
    const distributionStatusFilters = updateStatusFilters<DistributionStatusFilter>(
      filterValue?.distributionStatusFilters ?? [],
      DISTRIBUTION_STATUS_PREFIX,
    );

    const updatedFilter: StatusFilterValue = {
      clientFormStatusFilters,
      periodicReviewStatusFilters,
      distributionStatusFilters,
    };
    const isAllEmpty = Object.values(updatedFilter).every((filter) => filter.length === 0);
    setFilters(columnConfig, selectedTemplateId, isAllEmpty ? undefined : updatedFilter);
  };

  const toggleSelectAll = (selectAll: boolean) => {
    const selectAllOptions = (options: Option<string, boolean>[], prefix: string) => {
      const selectedValues = options
        .filter((option) => !option.disabled && option.id.startsWith(prefix))
        .map((option) => parseInt(option.id.replace(prefix, '')));

      return selectAll ? selectedValues : [];
    };

    const updatedFilter: StatusFilterValue = {
      clientFormStatusFilters: selectAllOptions(defaultStatusFilters, FORM_STATUS_PREFIX),
      periodicReviewStatusFilters: selectAllOptions(defaultStatusFilters, PERIODIC_STATUS_PREFIX),
      distributionStatusFilters: selectAllOptions(defaultStatusFilters, DISTRIBUTION_STATUS_PREFIX),
    };

    const isAllEmpty = Object.values(updatedFilter).every((filter) => filter.length === 0);

    setFilters(columnConfig, selectedTemplateId, isAllEmpty ? undefined : updatedFilter);
  };

  const isSelected = (option: Option<string, boolean>) => {
    if (!filterValue) return false;

    if (option.id.startsWith(FORM_STATUS_PREFIX)) {
      return filterValue.clientFormStatusFilters.includes(parseInt(option.id.replace(FORM_STATUS_PREFIX, '')));
    } else if (option.id.startsWith(PERIODIC_STATUS_PREFIX)) {
      return filterValue.periodicReviewStatusFilters.includes(parseInt(option.id.replace(PERIODIC_STATUS_PREFIX, '')));
    } else if (option.id.startsWith(DISTRIBUTION_STATUS_PREFIX)) {
      return filterValue.distributionStatusFilters.includes(parseInt(option.id.replace(DISTRIBUTION_STATUS_PREFIX, '')));
    }

    return false;
  };

  return (
    <div>
      <div className="text-dpm-14 my-1 flex flex-row justify-between font-medium">
        <span className="cursor-pointer hover:underline" {...mouseAndKeyboardCallbackProps(() => toggleSelectAll(true))} data-cy="select-all">
          {t('common:list.filter.select-all')}
        </span>

        <span className="cursor-pointer hover:underline" {...mouseAndKeyboardCallbackProps(() => toggleSelectAll(false))} data-cy="clear-all">
          {t('common:list.filter.clear-all')}
        </span>
      </div>

      <div className="-mr-4 -mt-2 h-64 w-72 space-y-4 overflow-y-auto">
        {defaultStatusFilters.map((option) => {
          if (option.disabled && option.hasDivider) {
            return (
              <div key={option.id} className="border-gray-5 mt-4 border-b">
                {option.text}
              </div>
            );
          }

          return (
            <Checkbox
              key={option.id}
              value={isSelected(option)}
              disabled={option.disabled}
              label={option.text}
              onChange={(value) => updateFilter(option, value)}
              role="menuitem"
            />
          );
        })}
      </div>
    </div>
  );
};

export default StatusFilter;
